export const ADMIN = "admin";
export const DEFAULT = "Default Sort Direction";
export const LOW_HIGH = "price from low - high";
export const HIGH_LOW = "price from high - low";
export const PRODUCT = "products";
export const SINGLE_PRODUCT = "singleProduct";
export const CATEGORY = "category";
export const AMBASSADOR = "ambassador";
export const AUTH_USER = "authorized-users";
export const DISCOUNT_CODE = "discount-code";
export const SHOPPINGS = "shoppings";
export const PURCHASE = "purchased";
export const CHECKOUT = "checkout";
export const CLOUDINARY = "cloudinaryImage";
export const WIGS = "wigs";
export const WIGS_LINK = "/virgin-wigs";
export const BUNDLES = "bundles";
export const ANONYMOUS = "anonymous";
export const JETBLACK = "jet black";
export const FRONTAL_CLOSURE = "closure-frontal";
export const JETBLACK_BLONDEHAIR = "jet-black-&-blonde-hair";
export const NATURALCURLY_CLOSURE = "naturalcurly&closure";
export const NATURALWAVE_FRONTAL = "naturalcurly&frontal";
export const BODYWAVE_CLOSURE = "bodywave&closure";
export const BODYWAVE_FRONTAL = "bodywave&frontal";
export const ALL_BUNDLES = "/all-bundles";
export const HAIR_BUNDLES = "hair bundle";
export const VIRGINHAIR = "virgin hair";
export const VIETNAMESEHAIR = "vietnamese hair";
export const VIETNAMESEWIG = "vietnamese wig";
export const VIETNAMESE = "vietname";
export const BUNDLES_LINK = "/bundles/indian";
export const BUNDLES_VIRGIN_LINK = "/bundles/virgin";
export const CLOSURE_FRONTAL = "Closure / Frontal";
export const CLOSURE_FRONTAL_LINK = "/closure-frontal/indian";
export const CLOSURE_FRONTAL_VIRGIN_LINK = "/closure-frontal/virgin";
export const CLOSURE_FRONTAL_VIETNAMESE_LINK = "/closure-frontal/vietnamese";
export const JET_BLACK_BLONDE = "Jet Black / Blonde";
export const JET_BLACK_BLONDE_LINK = "/jet-black-&-blonde/indian";
export const INDIAN_BUNDLE_DEAL = "Indian Deals";
export const INDIAN_BUNDLE_DEAL_LINK = "/indian-bundles-deals";
export const VIETNAMESE_BUNDLE = "/bundles/vietnamese";
export const VIETNAMESE_DEALS = "Vietnamese Deals";
export const VIETNAMESE_WIGS_LINK = "/vietnamese-wigs";
export const VIETNAMESE_DEALS_LINK = "/vietnamese-bundles-deals";
export const VIRGIN_DEALS = "Virgin Deals";
export const VIRGIN_DEALS_LINK = "/Virgin-bundles-deals";
export const ABOUT_US = "About Us / Contact Us";
export const TERMS_CONDITION = "Terms and Conditions";
export const SHIPPING_POLICIES = "Shipping & Returns Policy";
export const PRIVACY_POLICIES = "Privacy Policy";
export const UNITED_STATES = "United States";
export const AMB = "ambassador";
export const VIRGINWIG = "virgin wig";
export const FRONTAL = "frontal";
export const CLOSURE = "closure";
export const NATURAL_BLACK = "natural black";
export const BLONDE613 = "blonde613";
export const STRAIGHT = "straight";
export const BODYWAVE = "bodywave";
export const CURLY = "curly";
export const WAVY = "wavy";
export const WHATSAPPBASEURL = "https://wa.me/";
export const MODELEST_EMAIL = "modelEst1010@gmail.com";
export const EMAILJS_SERVICE = "service_53u3i5m";
export const EMAILJS_TEMPLATE = "template_apatle9";
export const EMAILJS_PACKAGE_COMING_TEMPLATE = "template_srjn4kg";
export const EMAILJS_PUBLIC_KEY = "bVp3p8HUJVpYqOxF8";
export const DEVELOPER = {
  NETLIFY: "https://isijolaoluwatosin.netlify.app/",
  LINKEDIN: "https://www.linkedin.com/in/oluwatosin-isijola-33333ba8/",
};
export const MODELEST_INSTAGRAM =
  "https://www.instagram.com/modelestluxuryhair/";
export const MODELEST_TIKTOK = "https://www.tiktok.com/@es.motayo";
export const ROLE = {
  GLOBAL_LEAD: "global lead",
  REGIONAL_LEAD: "regional lead",
};
export const MONTHS = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};
export const SHIPPING_COST = {
  canada: 20,
  usa: 10,
  london: 20,
  nigeria: 50,
};
export const DEALSOPTIONLENGTHARRAY = [
  "14inch, 16inch, & 18inch",
  "16inch, 18inch, & 20inch",
  "18inch, 20inch, & 22inch",
  "20inch, 22inch, & 24inch",
];
export const CANADA = "canada";
export const USERS = "users";
export const BOOKINGS = "bookings";
export const CURRENCY = "usd";

// Back end url
export const HTTPS_LINK = "http://localhost:5000";
// export const HTTPS_LINK = 'https://www.model-est.com'

export const AUTHORIZED_ID = {
  id_one: "estherodi1010@gmail.com",
  id_two: "tisijola7@gmail.com",
};

export const DISCOUNT_PERCENTAGE = 15;

export const BASE_CURRENCY_CONVERTER_URL =
  "https://api.apilayer.com/exchangerates_data/convert?";
export const googleSearch = "https://www.google.com/maps/search/";
export const location = "springview ct. Glen Burnie. Baltimore. Maryland. USA";

export const priceObjectArray = [10, 14, 16, 18, 20, 22, 24, 26, 28, 30];

// test
// test 2
// test 3
// test 4

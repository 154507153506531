/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebase from "../firebase";
import Layout from "../components/shared/Layout";
import { auth } from "../firebase";
import Button from "../components/shared/Button";
import { Helmet } from "react-helmet";
import { useGlobalContext } from "../context/useContextHook";
import useForm from "../utils/useForm";

const Login = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [inputValues, handleChange] = useForm();
  const [isReset, setIsReset] = React.useState(false);
  const [error, setError] = useState(null);
  const [showpswd, setShowpswd] = useState(false);
  const [sales, setSales] = React.useState(null);

  const { fetchSalesPercentage } = useGlobalContext();
  React.useEffect(() => {
    inputRef.current.focus();
    setSales(fetchSalesPercentage());
  }, []);

  const togglePassword = () => {
    setShowpswd(!showpswd);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(
        inputValues?.email,
        inputValues?.password
      );
      navigate("/");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const resetPassword = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(inputValues?.email)
      .then(() => {
        try {
          setIsReset(true);
        } catch (error) {
          console.log(`error message - ${error.message}`);
        }
      });
  };

  return (
    <div>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Layout>
        <main
          className={`${
            sales ? "tw-pt-[80px]" : ""
          } tw-w-full tw-bg-[rgba(255,255,255,0.1)] tw-pb-10 home`}>
          <section className="tw-flex tw-flex-col tw-items-center tw-pt-[150px] lg:tw-pt-[100px]">
            <div className="tw-text-center">
              <h1 className="tw-text-2xl tw-font-bold tw-mb-10 tw-text-neutral-50">
                Sign In
              </h1>
              {/* <p className="tw-w-[55%] tw-mx-auto tw-my-10">
								Fill in your login credentials to gain access to your personal
								and cart history page.
							</p> */}
            </div>
            <article className="tw-w-full">
              <form onSubmit={handleSubmit}>
                <div>
                  <input
                    ref={inputRef}
                    type="text"
                    name="email"
                    id="email"
                    value={inputValues?.email || ""}
                    onChange={handleChange}
                    placeholder="Email"
                    className="tw-mt-1 tw-block lg:tw-w-[30%] tw-mx-auto tw-w-[70%] tw-px-3 tw-py-2 tw-border tw-border-neutral-100 tw-text-sm tw-shadow-xl tw-placeholder-gray-400 focus:tw-outline-none focus:tw-border-gray-200 focus:tw-ring-1 focus:tw-ring-gray-200 isabled:tw-bg-gray-50 disabled:tw-text-gray-500 disabled:tw-border-gray-200 disabled:tw-shadow-none invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 tw-outline-0 tw-mb-5"
                  />
                </div>
                <div className="tw-relative tw-h-[38.4px] lg:tw-w-[30%] tw-w-[70%] tw-mx-auto tw-shadow-xl">
                  <input
                    type={showpswd ? "text" : "password"}
                    name="password"
                    id="password"
                    value={inputValues?.password || ""}
                    onChange={handleChange}
                    placeholder="Password"
                    className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-neutral-100 tw-text-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-border-gray-200 focus:tw-ring-1 focus:tw-ring-gray-200 disabled:tw-bg-gray-50 disabled:tw-text-gray-500 disabled:tw-border-gray-200 disabled:tw-shadow-none invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 tw-outline-0 tw-mb-5"
                  />
                  <div className="tw-text-neutral-500 tw-font-light tw-flex tw-flex-row tw-justify-center tw-items-center tw-absolute tw-top-0 tw-bg-pink-900 tw-pr-2 tw-h-full tw-right-0 tw-w-[50px] tw-text-center tw-border tw-border-pink-900">
                    <input
                      className="tw-border-gray-300 tw-ml-2 tw-rounded-full"
                      type="checkbox"
                      onClick={togglePassword}
                    />
                  </div>
                </div>
                <div className="tw-text-red-800 tw-text-xs tw-mt-5 tw-text-center">
                  {error ? <p>{error}</p> : null}
                </div>
                <div className="tw-flex tw-flex-row tw-my-10 tw-justify-center">
                  <Button onClick={handleSubmit} type="submit">
                    Sign In
                  </Button>
                </div>
              </form>
            </article>
            <div className="tw-text-neutral-100 tw-items-center tw-text-sm tw-flex tw-flex-col tw-font-light ">
              <span>
                Forgot Password? |{" "}
                <button
                  onClick={() => resetPassword(inputValues?.email)}
                  className="tw-text-pink-800 tw-underline">
                  Reset
                </button>
              </span>
              {isReset && (
                <div className=" tw-text-blue-800 tw-mt-5">
                  <span>{`A password reset link has been sent to this email - ${inputValues?.email}`}</span>
                </div>
              )}
              <p className="tw-mt-5 tw-mb-10">
                Not a Member? |{" "}
                <Link to="/register">
                  <button className="tw-text-pink-800 tw-underline">
                    Create Account
                  </button>
                </Link>
              </p>
            </div>
          </section>
        </main>
      </Layout>
    </div>
  );
};

export default Login;
